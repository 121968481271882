import Vue from 'vue'
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import axios from "axios";
import store from './store'
import updateToken from "@/middlewares/update-token";
import { keycloakUrl, keycloakRealm } from "@/env";
import initWidget from './modules/initWidget.js';
import 'document-register-element/build/document-register-element'
import PassepartoutWidget from './PassepartoutWidget.js';
import eventsManager from "./modules/eventsManager";

Vue.config.productionTip = false;

function tokenInterceptor() {
  axios.interceptors.request.use(
    async (config) => {
      if (Vue.prototype.$keycloak.authenticated) {
        const token = await updateToken();
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

let token = ''
let refreshToken = ''
let idToken = ''
const ppScript = document.getElementById("passepartout-script")

if (ppScript !== null) {
  token = ppScript.getAttribute("token");
  refreshToken = ppScript.getAttribute("refresh-token");
  idToken = ppScript.getAttribute("id-token");
}

Vue.use(VueKeyCloak, {
  init: {
    onLoad: "check-sso",
    pkceMethod: "S256",
    checkLoginIframe: false,
    token: token,
    refreshToken: refreshToken,
    idToken: idToken,
  },
  config: {
    realm: keycloakRealm,
    url: keycloakUrl + '/auth',
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
  },
  onReady: async (keycloak) => {
    store.commit("setKeycloak", keycloak);

    tokenInterceptor();

    initWidget.init().then(async () => {
      window.PassepartoutWidget = PassepartoutWidget;
      if (Vue.prototype.$keycloak.authenticated && !store.state.isUserDataFetched) {
        await store.dispatch('fetchUserData')
      }
      eventsManager.trigger('passepartout-widget:loaded', {
        'contentId': store.state.contentId,
        'contentTitle': store.state.contentTitle,
        'contentLede': store.state.contentLede,
        'contentPriceInCents': store.state.contentPriceInCents,
        'contentUrl': store.state.contentUrl,
        'publisherId': store.state.publisherId,
      });
    })
  },
});
