import { getCardProviderLogo } from "@/utils";
import eventsManager from '../modules/eventsManager.js'

export const mutations = {
  setStoreParam(state, { key, value }) {
    const oldValue = state[key];
    state[key] = value;
    if (key === 'contentId' && oldValue !== state[key]) {
      eventsManager.trigger('passepartout-widget:new-content-loaded', {
        'oldContentId': oldValue,
        'newContentId': state[key],
      });
    }
  },
  closeDialog(state) {
    const initialState = state.isDialogOpen;
    state.isDialogOpen = false
    this.commit('resetScreen')
    if (initialState === true) {
      eventsManager.trigger('passepartout-widget:closed');
    }
  },
  setUserProfile(state, payload) {
    state.userProfile = payload;
  },
  resetUserProfile(state) {
    state.userProfile = {
      email: '',
      is_active: false,
      is_superuser: false,
      full_name: '',
      id: null,
      hasValidCreditCardRegistered: false,
    }
    state.isUserDataFetched = false;
  },
  setScreen(state, payload) {
    Object.assign(state.screen, payload)
  },
  resetScreen(state) {
    state.screen = {
      genericError: false,
      buyFeedback: false,
      rechargeWallet: false,
      accessAlreadyAvailable: false
    }
  },
  showErrorScreen(state, payload) {
    state.screen.genericError = true
    state.genericErrorText = payload
  },
  setEligibility(state, payload) {
    state.isEligible = payload.is_eligible;
    state.eligibilityStatus = payload.status;
    if (state.eligibilityStatus === 'ACCESS_AVAILABLE') {
      state.balanceType = undefined;
      state.userBalance = undefined;
      state.screen.accessAlreadyAvailable = true;
    } else {
      state.balanceType = payload.balance.type;
      state.userBalance = payload.balance.amount;
    }
  },
  setCreditCards(state, payload) {
    const creditCardsFormatted = payload.map((creditCard) => ({
      id: creditCard.id,
      alias: creditCard.alias,
      cardProvider: creditCard.card_provider,
      cardProviderLogo: getCardProviderLogo(creditCard.card_provider),
      currency: creditCard.currency,
      validity: creditCard.validity,
    }))
    state.creditCards = creditCardsFormatted;
  },
  setContentId(state, payload) {
    state.contentId = payload;
  },
  setKeycloak(state, payload) {
    state.keycloak = payload;
  },
  setProgress(state, payload) {
    state.progressMessage = payload.progressMessage;
  }
}