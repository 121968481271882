<template>
  <main-dialog />
</template>

<script>
import vuetify from "./plugins/vuetify.js";
import MainDialog from "./components/MainDialog.vue";
import eventsManager from "./modules/eventsManager.js";

export default {
  name: "App",
  vuetify,
  components: {
    MainDialog,
  },
  props: [
    "contentId",
    "contentTitle",
    "contentLede",
    "contentPriceInCents",
    "contentUrl",
    "publisherId",
    "apiKey",
    "publisherName",
    "publisherLogoSrc",
    "contentThumbnail",
    "primaryColor",
    "accentColor",
  ],
  watch: {
    contentId: function (newVal, oldVal) {
      eventsManager.trigger("passepartout-widget:new-content-loaded", {
        oldContentId: oldVal,
        newContentId: newVal,
      });
    },
  },
  mounted() {
    if (this.primaryColor) {
      this.$vuetify.theme.themes.light.primary = this.primaryColor;
    }
    if (this.accentColor) {
      this.$vuetify.theme.themes.light.accent = this.accentColor;
    }
    this.$store.state.contentId = this.contentId;
    this.$store.state.contentTitle = this.contentTitle;
    this.$store.state.contentLede = this.contentLede;
    this.$store.state.contentPriceInCents = this.contentPriceInCents;
    this.$store.state.contentUrl = this.contentUrl;
    this.$store.state.contentThumbnail = this.contentThumbnail;
    this.$store.state.publisherId = this.publisherId;
    this.$store.state.publisher.apiKey = this.apiKey; // only used if check access right is made front-end side.
    this.$store.state.publisher.name = this.publisherName;
    this.$store.state.publisher.logoUrl = this.publisherLogoSrc;

    // The widget must always be opened after the basic page info have been logged in the store.
    const url = new URL(window.location.href);
    const urlParams = new URLSearchParams(url.search);
    if (
      urlParams &&
      urlParams.has("openWidget") &&
      urlParams.get("openWidget").toLowerCase() === "true"
    ) {
      this.$store.dispatch("openDialog");
    }
    if (urlParams && urlParams.has("transactionId")) {
      this.$store.dispatch("checkForPayinError", urlParams.get("transactionId"));
    }
  },
};
</script>

<style src="vuetify/dist/vuetify.css"></style>

<style>
.v-card__actions > .v-btn.v-btn {
  padding: 0;
}

.v-application--is-ltr .v-card__actions > .v-btn.v-btn + .v-btn {
  /* to remove the default 8px */
  margin-left: 0px;
}

.primary-btn {
  background-color: var(--v-primary-base) !important;
  color: var(--v-accent-lighten3) !important;
}
</style>
