export const defaultState = {
    isDialogOpen: false,
    isBottomCardToggled: true,
    progressMessage: "",
    isUserDataFetching: false,
    isUserDataFetched: false,
    userProfile: {
        id: null,
        email: '',
        is_active: false,
        is_superuser: false,
        is_verified_email: false,
        full_name: '',
        hasValidCreditCardRegistered: false,
    },
    isTempEmail: false,
    contentId: undefined,
    contentTitle: undefined,
    contentLede: undefined,
    contentPriceInCents: undefined,
    contentUrl: undefined,
    contentThumbnail: undefined,
    publisherId: undefined,
    publisher: {
        apiKey: undefined,
        logoUrl: undefined,
        url: undefined,
        name: undefined,
    },
    screen: {
        buyFeedback: false,
        rechargeWallet: false,
        accessAlreadyAvailable: false,
        genericError: false,
    },
    keycloak: null,
    isEligible: undefined,
    eligibilityStatus: undefined,
    balanceType: undefined,
    userBalance: undefined,
    creditCards: undefined,
    genericErrorText: undefined
}