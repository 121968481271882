import eventsManager from '../modules/eventsManager.js'
import { appUrl } from "@/env";
import { api } from '@/api';

export const actions = {
    goToPassepartoutApp() {
        window.open(appUrl, '_blank').focus();
    },
    async openDialog(context) {
        const isDialogInitiallyOpened = context.state.isDialogOpen;
        
        // wait 1s in case the contentID is about to be filled by the client
        // useful when returning from keycloak login
        if (context.state.contentId === undefined) {
            console.log("[PASSEPARTOUT WIDGET] - no contentId found - wait for 1s before continuing")
            await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        context.state.isBottomCardToggled = true;
        context.state.isDialogOpen = true;

        if (isDialogInitiallyOpened === false) {
            eventsManager.trigger('passepartout-widget:opened');
        }
        if (context.state.keycloak.authenticated) {
            if (!context.state.isUserDataFetched || context.state.userProfile && !context.state.userProfile.is_verified_email) {
                context.commit('setProgress', (context, {
                    progressMessage: "Récupération de vos données"
                }))
                await context.dispatch('fetchUserData')
            }
            if (!context.state.isTempEmail) {
                context.commit('setProgress', (context, {
                    progressMessage: "Vérification du solde"
                }))
                context.dispatch('fetchUserEligibility', {
                    amount_in_cents: context.state.contentPriceInCents,
                    external_content_id: context.state.contentId,
                    url: context.state.contentUrl,
                    publisher_id: context.state.publisherId,
                })
            }
            context.commit('setProgress', (context, {
                progressMessage: ""
            }))
        }
    },
    async checkAccessRight(context) {
        try {
            const response = await api.checkAccessRight(
                context.state.userProfile.id,
                context.state.publisherId,
                context.state.contentId,
                context.state.publisher.apiKey,
            )
            if (response && response.data) {
                return {
                    isUserAllowed: !!response.data.is_valid,
                    contentId: context.state.contentId,
                    expiryDate: response.data.expiry_date,
                }
            }
        } catch (err) {
            console.log(err)
        }
        return {
            isUserAllowed: false,
            contentId: context.state.contentId,
            expiryDate: null,
        }
    },
    async fetchUserData(context) {        
        // pause concurrent calls if any
        let concurrencyCall = false
        while (context.state.isUserDataFetching) {
            concurrencyCall = true
            await new Promise((resolve) => setTimeout(resolve, 100));
        }
        if (concurrencyCall) {
            return
        }
        context.state.isUserDataFetching = true;
        
        try {
            await context.dispatch('fetchUserProfile', context)
        } catch {
            context.dispatch('resetUserProfile', (context));
        }
        
        context.state.isUserDataFetching = false;
        context.state.isUserDataFetched = true;
        eventsManager.trigger('passepartout-widget:user-profile-fetched')
    },
    async fetchUserProfile(context) {
        try {
            const response = await api.getMeOrCreate(
                context.state.keycloak.token,
                window.location.href,  // used for redirecting to article after signup or payment
            );
            if (response.data) {
                context.commit('setUserProfile', (context, response.data));
            } else {
                context.dispatch('resetUserProfile', (context));
            }
        } catch (error) {
            context.state.isTempEmail = true;
        }
    },
    async fetchUserEligibility(context, payload) {
        try {
            const response = await api.getUserEligibility(context.state.keycloak.token, payload)
            if (response.data) {
                context.commit('setEligibility', (context, response.data));
            }
        } catch {
            context.commit('showErrorScreen', (context, "Erreur de communication avec l'API"))
        }
    },
    async fetchUserCreditCard(context) {
        try {
            const response = await api.getCreditCards(context.state.keycloak.token, true);
            if (response.data) {
                context.commit('setCreditCards', (context, response.data));
            }
        } catch (error) {
            context.commit('showErrorScreen', (context, "Erreur de communication avec l'API"))
        }
    },
    async purchaseContent(context, payload) {
        try {
            await api.purchaseContent(context.state.keycloak.token, payload)
            eventsManager.trigger('passepartout-widget:content-purchased', {
                amountInCents: context.state.contentPriceInCents,
                publisherId: context.state.contentId,
            });
            context.commit('setScreen', (context, {
                buyFeedback: true
            }))
        } catch (err) {
            context.commit('showErrorScreen', (context, "Erreur de communication avec l'API"))
            console.log(err)
        }
    },
    async createRechargeWallet(context, payload) {
        try {
            const response = await api.createRechargeWallet(context.state.keycloak.token, payload)
            if (response.data.secure_mode_needed) {
                window.location.replace(response.data.secure_mode_redirect_url);
            }
            else if (response.data.status == "SUCCEEDED" || response.data.status == "CREATED") {
                if (response.data.status == "CREATED") {
                    await setTimeout(2000);
                }
                context.dispatch('fetchUserEligibility', {
                    amount_in_cents: context.state.contentPriceInCents,
                    external_content_id: context.state.contentId,
                    url: context.state.contentUrl,
                    publisher_id: context.state.publisherId,
                })
                context.commit('setScreen', (context, {
                    rechargeWallet: false
                }))
            } else {
                throw 'Payin Failed';
            }
        } catch (error) {
            context.commit('setScreen', (context, {
                rechargeWallet: false,
                genericError: true
            }))
            context.commit('setStoreParam', {
                key: 'genericErrorText', value: "Le paiement a échoué, veuillez réessayer."
            })
        }
    },
    async checkForPayinError(context, payload) {
        try {
            context.commit('setProgress', (context, {
                progressMessage: "Récupération des données de paiement"
            }))
            const response = await api.getPayin(
                context.state.keycloak.token,
                payload
            );
            context.commit('setProgress', (context, {
                progressMessage: ""
            }))
            if (response.data && response.data.status == "FAILED") {
                context.commit('showErrorScreen', (context, "Le paiement a échoué, veuillez réessayer."))
            } else {
                throw 'No response.data'
            }
        } catch {
            context.commit('showErrorScreen', (context, "Impossible de récupérer les données du paiement."))
        }
    },
    async sendVerificationEmail(context) {
        await api.sendVerificationEmail(context.state.keycloak.token, { redirect_uri: window.location.href });
    },
}