<template>
  <v-app>
    <!-- the line :attach="$parent.$el" is used 
    because we mount vuetify in a shadow DOM.
    checkout https://stackoverflow.com/questions/69254008/vuetify-not-rendering-v-menu-when-built-as-a-webcomponent
    -->
    <v-bottom-sheet
      v-if="$vuetify.breakpoint.xs"
      :attach="$parent.$el"
      v-model="isDialogOpen"
      content-class="main-dialog-mobile"
    >
      <bottom-card />
      <main-card />
    </v-bottom-sheet>

    
    <!-- TODO ensure that clicking outside the modal will close the modal -->
    <v-dialog
      v-else
      :attach="$parent.$el"
      v-model="isDialogOpen"
      max-width="500"
      content-class="main-dialog-desktop"
    >
      <bottom-card />
      <main-card />
    </v-dialog>
  </v-app>
</template>

<script>
import MainCard from "./MainCard.vue";
import BottomCard from "./BottomCard.vue";

import {
  VDialog,
  VApp,
} from "vuetify/lib";

export default {
  name: "MainDialog",
  components: {
    VApp,
    MainCard,
    BottomCard,
    VDialog,
  },
  computed: {
    isDialogOpen: {
      // this computed props makes sure we use the proper
      // methods to open/close the dialog
      get() {
        return this.$store.state.isDialogOpen;
      },
      set() {
        if (this.$store.state.isDialogOpen) {
          return this.$store.commit('closeDialog');
        }
        return this.$store.dispatch('openDialog');
      }
    },
  },
};
</script>

<style>
.v-application--wrap {
  display: none !important;
}
.main-dialog-desktop {
  border-radius: 20px;
  background-color: var(--v-primary-base);
}

.main-dialog-mobile {
  max-height: none !important;
  border-radius: 20px 20px 0px 0px !important;
  background-color: var(--v-primary-base);
}
</style>
